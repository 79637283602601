//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'Main',
  layout: ({ store }) => {
    return 'partner'
  },
  computed: {
    ...mapGetters('landings', ['featureEnabled']),
    component() {
      return () => import('@/components/page/partner/MainPartner')
    },
  },
}
